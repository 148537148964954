@tailwind base;

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  font-display: swap;
  src: url("/fonts/Inter-roman.var.woff2") format("woff2");
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  font-display: swap;
  src: url("/fonts/Inter-italic.var.woff2") format("woff2");
}

/* Legacy naming compatibility */
@font-face {
  font-family: 'Inter var alt';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  font-display: swap;
  src: url("/fonts/Inter-roman.var.woff2") format("woff2");
}
@font-face {
  font-family: 'Inter var alt';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  font-display: swap;
  src: url("/fonts/Inter-italic.var.woff2") format("woff2");
}

h1 {
    @apply text-2xl;
}
h2 {
    @apply text-6xl text-primary-lighter;
}
h3 {
    @apply text-3xl mb-3 font-semibold text-primary-lighter;
}
h4 {
  @apply text-lg mb-3 font-semibold text-primary-lighter;
}
h5 {
  @apply mb-3 font-semibold text-primary-lighter;
}
p {
    @apply mt-2 mb-2;
}
a .link, main.page a:not(.btn) {
    @apply text-link underline;
}
a .link, main.page a:hover:not(.btn) {
  @apply text-link-hover underline;
}

a h1 {
  @apply text-2xl no-underline;
}

.nav-item {
  @apply text-secondary no-underline ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 transition duration-150 ease-in-out;
}

.nav-item-hb {
  @apply block ml-0 mt-1;
}

.nav-item:hover {
  @apply text-secondary bg-primary-lighter;
}

.nav-item-active {
  @apply bg-primary-darker;
}

.nav-item:focus {
  @apply outline-none text-secondary bg-primary-lighter;
}

.nav-item-cta {
  @apply bg-cta text-primary;
}
 
.nav-item-cta:hover {
  @apply bg-cta-darker text-primary;
}

.btn {
  @apply p-2 text-lg mt-10 rounded-lg no-underline text-primary whitespace-nowrap inline-flex items-center;

  @screen sm {
    @apply pl-5 pr-5 text-xl;
  }
}

.btn-small {
  @apply p-2 pt-1 pb-1 text-sm m-0;
}

.btn-small:hover {
  @apply bg-secondary-darker;
}

.btn-small-loading {
  @apply animate-spin;
}

.btn-loading {
  @apply animate-spin -ml-1 mr-3 h-5 w-5 text-primary;
}

.btn-cta {
  @apply bg-cta no-underline;
}

.btn-cta:hover {
  @apply bg-cta-darker;
}

a.btn-cta {
  @apply no-underline;
}

/* Page */
main.page {
  @apply max-w-7xl mx-auto pb-10;

  @screen sm {
    @apply px-6;
  }

  @screen lg {
    @apply px-8;
  }
}

main.page section {
  @apply mt-10 p-3 mb-10;
}

.blocks {
  @apply flex flex-wrap;
}

.blocks-2 div {
  @apply w-full;

  @screen md {
    @apply w-1/2 p-2;
  }
}

main.page h2 {
  @apply mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight;

  @screen sm {
    @apply text-4xl leading-10;
  }
}

main.page h3:not(.custom) {
  @apply mt-8;
}

main.page ul:not(.list-none) {
  @apply list-disc ml-8;
}

main.page p.highlight {
  @apply bg-secondary-darker p-5 mt-5 mb-5;
}

main.page table td {
  @apply p-1 pl-3 pr-3 align-text-top;
}

main.page table th {
  @apply pb-3;
}

main.page table .separator {
  @apply h-7;
}

main.page table .odd-row {
  @apply bg-secondary-darker;
}

main.page table .pause {
  @apply italic text-primary-lighter;
}

a.btn-cta {
  @apply no-underline;
}

.form-input:disabled {
  @apply opacity-75;
}


@tailwind components;



@tailwind utilities;

